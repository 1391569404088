@font-face {
  font-family: 'icomoon';
  src: url('/build/assets/frontend/fonts/icomoon.eot?7s6olz');
  src: url('/build/assets/frontend/fonts//icomoon.eot?7s6olz#iefix') format('embedded-opentype'), url('/build/assets/frontend/fonts/icomoon.ttf?7s6olz') format('truetype'), url('/build/assets/frontend/fonts/icomoon.woff?7s6olz') format('woff');
  font-weight: normal;
  font-style: normal;
}
[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-chev-right-thin-double:before {
  content: "\ea3c";
}
.icon-chev-left-thin-double:before {
  content: "\ea40";
}

@charset "UTF-8";
@font-face {
  font-family: 'icomoon';
  src: url('/build/assets/frontend/fonts/icomoon.eot?7s6olz');
  src: url('/build/assets/frontend/fonts//icomoon.eot?7s6olz#iefix') format('embedded-opentype'), url('/build/assets/frontend/fonts/icomoon.ttf?7s6olz') format('truetype'), url('/build/assets/frontend/fonts/icomoon.woff?7s6olz') format('woff');
  font-weight: normal;
  font-style: normal;
}
[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-chev-right-thin-double:before {
  content: "\ea3c";
}
.icon-chev-left-thin-double:before {
  content: "\ea40";
}
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}
.slick-slider .slick-track,
.slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
}
.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
}
.slick-track:before,
.slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}
.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir="rtl"] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
  display: none;
}
/* Slider */
.slick-loading .slick-list {
  background: #fff url('../img/ajax-loader.gif') center center no-repeat;
}
/* Icons */
@font-face {
  font-family: 'slick';
  font-weight: normal;
  font-style: normal;
  src: url('../fonts/slick.eot');
  src: url('../fonts/slick.eot?#iefix') format('embedded-opentype'), url('../fonts/slick.woff') format('woff'), url('../fonts/slick.ttf') format('truetype'), url('../fonts/slick.svg#slick') format('svg');
}
/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
}
.slick-prev:hover,
.slick-next:hover,
.slick-prev:focus,
.slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}
.slick-prev:hover:before,
.slick-next:hover:before,
.slick-prev:focus:before,
.slick-next:focus:before {
  opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}
.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  color: white;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-prev {
  left: -25px;
}
.slick-prev[dir="rtl"] {
  left: auto;
  right: -25px;
}
.slick-prev:before {
  content: "←";
}
.slick-prev:before[dir="rtl"] {
  content: "→";
}
.slick-next {
  right: -25px;
}
.slick-next[dir="rtl"] {
  left: -25px;
  right: auto;
}
.slick-next:before {
  content: "→";
}
.slick-next:before[dir="rtl"] {
  content: "←";
}
/* Dots */
.slick-dotted .slick-slider {
  margin-bottom: 30px;
}
.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}
.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}
.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1;
}
.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  width: 20px;
  height: 20px;
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: black;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  color: black;
  opacity: 0.75;
}
.sr-text {
  position: absolute !important;
  top: -9999px !important;
  left: -9999px !important;
}
.slick-slider .slick-prev,
.slick-slider .slick-next {
  z-index: 100;
  font-size: 2.5em !important;
  height: 40px;
  width: 40px;
  margin-top: -20px;
  position: absolute;
  top: 50%;
  text-align: center;
  color: #121212;
  opacity: .5;
  transition: opacity .25s;
  cursor: pointer;
}
.slick-slider .slick-prev:hover,
.slick-slider .slick-next:hover {
  opacity: .8;
}
.slick-slider .slick-prev i,
.slick-slider .slick-next i {
  font-size: inherit !important;
}
.slick-slider .slick-prev {
  left: 0;
}
.slick-slider .slick-next {
  right: 0;
}
.slick-slider-wrapper {
  margin-top: 15px !important;
  width: 100%;
  font-family: helvetica, arial, sans-serif !important;
  font-weight: 400;
}
.slick-slider-wrapper strong {
  font-weight: 300;
  letter-spacing: 2px;
  font-size: 1.2em;
  display: block;
  text-align: center;
  text-transform: uppercase;
  margin: 0;
  padding: 20px 0;
  color: #666;
}
.slick-slider-wrapper .slick-arrow:before {
  content: "" !important;
}
.slick-slider-wrapper .thumb-nav {
  margin: 0 auto;
  margin-top: 50px;
  max-width: 80%;
  padding: 0;
  text-align: center;
}
.slick-slider-wrapper .thumb-nav .slick-list {
  left: 14px !important;
}
.slick-slider-wrapper .thumb-nav.slick-slider .slick-prev,
.slick-slider-wrapper .thumb-nav.slick-slider .slick-next {
  font-size: 2.2em;
  height: 33px;
  margin-top: 0px;
}
.slick-slider-wrapper .thumb-nav.slick-slider .slick-prev {
  margin-left: -50px;
}
.slick-slider-wrapper .thumb-nav.slick-slider .slick-next {
  margin-right: -50px;
}
.slick-slider-wrapper .thumb-nav li {
  display: block;
  margin: 0 0 0 10px;
  cursor: pointer;
}
.slick-slider-wrapper .thumb-nav li:hover,
.slick-slider-wrapper .thumb-nav li:focus {
  border-color: #999;
}
.slick-slider-wrapper .thumb-nav li.slick-current img {
  opacity: 1;
}
.slick-slider-wrapper div.black-mode {
  background-color: #000000;
  padding-top: 1px;
  padding-bottom: 1px;
}
.slick-slider-wrapper div.black-mode .thumb-nav {
  margin-top: 15px;
  margin-bottom: 3px;
  margin-right: 80px;
}
.slick-slider-wrapper div.black-mode .thumb-nav .slick-arrow {
  color: #ffffff;
}
.slick-slider-wrapper div.black-mode .thumb-nav .slick-next {
  margin-right: -63px !important;
}
.main-img-slider figure {
  margin: 0 auto;
  padding: 0 2em;
  text-align: center;
}
.main-img-slider figure a {
  cursor: pointer;
  cursor: zoom-in;
  line-height: 300px;
}
.main-img-slider figure a img.img-responsive {
  max-height: 300px!important;
  margin: auto!important;
  width: auto;
  display: inline-block;
  line-height: 300px;
  vertical-align: middle;
  float: none !important;
}
.main-img-slider figure p {
  text-align: center;
}
.slick-list .slick-track {
  margin-bottom: 10px;
}
.slick-slide {
  width: 90px;
}
@media (max-width: 768px) {
  .main-img-slider figure {
    padding: 0;
  }
  .slick-slider-wrapper {
    margin: auto;
  }
  .slick-slider-wrapper .thumb-nav {
    max-width: 100%;
  }
  .slick-slider-wrapper div.black-mode .thumb-nav {
    margin-right: 26px;
  }
  .i-chev-left-thin {
    display: none;
  }
  .i-chev-right-thin {
    display: none;
  }
}

/*# sourceMappingURL=carousel-gallery.css.map */
