article.detail-box #gallery {
  float: left;
}
article.detail-box #gallery img {
  margin-right: -20.5px;
  margin-bottom: 0;
  float: none;
}
article.detail-box #gallery span {
  font-size: 12px;
  color: #888;
  font-weight: initial;
  margin-left: -35.5px;
}
@media (max-width: 767px) {
  article.detail-box #gallery {
    float: left;
  }
  article.detail-box #gallery img {
    margin-right: 17.5px;
    float: none;
  }
  article.detail-box #gallery span {
    margin-left: 0px;
  }
}
@media (max-width: 450px) {
  article.detail-box #gallery {
    float: none;
    margin-bottom: 15px;
  }
  article.detail-box #gallery img {
    margin: 0;
    width: 100%;
    max-width: 100%;
  }
  article.detail-box #gallery span {
    margin-left: 0px;
  }
}

.nav-pipe {
  display: inline;
}
.nav-pipe:before {
  content: '';
  width: 1px;
  min-height: 33px;
  background-color: #e6e6e6;
  display: inline-block;
  margin: 0 17.5px;
  position: relative;
  top: 10px;
}
#tts {
  padding-bottom: 17px;
}
#tts div {
  display: inline;
}
#tts div:first-child {
  float: left;
}
#tts div:first-child span {
  margin: 5px 10px 0px 0px;
  display: block;
}
#tts a {
  cursor: pointer;
  color: black;
  display: inline-block;
}
#tts svg {
  font-size: 2.8rem;
}
.icn-instagram-custom {
  width: 22px !important;
  height: 24px !important;
}
.text-videos {
  font-weight: 700;
  color: #ef9319;
  padding-top: 15px;
  font-size: 20px;
}
#videovangelo .tab-content {
  padding-top: 0px !important;
  padding-bottom: 15px;
}
@media (max-width: 960px) {
  .text-videos {
    font-size: 15px;
  }
}

#widget-radio-wrapper {
  color: #212121;
  font-family: 'San Francisco Display', Lato, Helvetica, Arial, Geneve, sans-serif;
  font-size: 9pt;
  margin-top: 32px;
  width: 262.5px;
}
#widget-radio-wrapper #Player {
  background-image: url('/build/assets/frontend/img/newradio/widget-bg.jpg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top left;
  position: relative;
}
#widget-radio-wrapper .musesStyleReset > img {
  display: none;
}
/* Ascolta radio voce */
#widget-radio-wrapper #MusesRadioPlayer-HTML5-player-0 div div,
#widget-radio-wrapper #MusesRadioPlayer-HTML5-player-1 div div {
  color: #212121 !important;
  position: absolute;
  left: 100px;
  top: 2px;
  font-size: 16px !important;
}
#widget-radio-wrapper #MusesRadioPlayer-HTML5-player-0 div[title="play"],
#widget-radio-wrapper #MusesRadioPlayer-HTML5-player-0 div[title="stop"] {
  z-index: 9999;
  left: 52px !important;
  top: 0 !important;
  width: 47px;
  height: 49px;
}
#widget-radio-wrapper #MusesRadioPlayer-HTML5-player-0 div[title="play"] img,
#widget-radio-wrapper #MusesRadioPlayer-HTML5-player-0 div[title="stop"] img {
  position: absolute;
  left: 50% !important;
  top: 50% !important;
  transform: translate(-50%, -50%);
}
#widget-radio-wrapper #MusesRadioPlayer-HTML5-player-0 div[title="stop"] {
  background-color: #fff;
}
#widget-radio-wrapper #MusesRadioPlayer-HTML5-player-0 div[title="play"] img {
  /* margin-left: 2px; */
  opacity: 0 !important;
}
#widget-radio-wrapper #MusesRadioPlayer-HTML5-player-0 div[title="stop"]:after,
#widget-radio-wrapper #MusesRadioPlayer-HTML5-player-0 div[title="stop"]:before {
  content: '';
  width: 8px;
  height: 24px;
  position: absolute;
  left: 50% !important;
  top: 50% !important;
  transform: translate(-50%, -50%);
  background-color: #212121;
}
#widget-radio-wrapper #MusesRadioPlayer-HTML5-player-0 div[title="stop"]:before {
  left: 48% !important;
  margin-left: -6px;
}
#widget-radio-wrapper #MusesRadioPlayer-HTML5-player-0 div[title="stop"]:after {
  left: 52% !important;
  margin-left: 6px;
}
#widget-radio-wrapper #MusesRadioPlayer-HTML5-player-0 div[title="stop"] img {
  display: none !important;
}
/*  Hide streaminfo */
#widget-radio-wrapper .cc_streaminfo {
  display: none !important;
}
/* temporary volume */
/* #main-nav #widget-radio-wrapper #MusesRadioPlayer-HTML5-player-0 div img + div{
  position: relative !important;
  top: -48px !important;
  height: 2px !important;
  width: 57px !important;
  left: 183px !important;
} */
/* @media only screen and (min-width: 768px) {
  #main-nav #widget-radio-wrapper{
    background: #fff;
  }
} */
@media screen and (max-width: 1199px) {
  #widget-radio-wrapper {
    max-width: 100%;
    max-height: 42px;
  }
}
/* Responsive XS */
@media screen and (max-width: 299px) {
  #widget-radio-wrapper #MusesRadioPlayer-HTML5-player-0 div div,
  #widget-radio-wrapper #MusesRadioPlayer-HTML5-player-1 div div {
    text-overflow: ellipsis;
    overflow: hidden;
    display: none;
  }
}
/* Responsive XS & Small devices Tablets  @screen-xs-max */
@media screen and (max-width: 767px) {
  #widget-radio-wrapper {
    width: 100%;
  }
  #widget-radio-wrapper #MusesRadioPlayer-HTML5-player-0 div div,
  #widget-radio-wrapper #MusesRadioPlayer-HTML5-player-1 div div {
    left: 120px;
    top: 6px;
  }
  #widget-radio-wrapper #MusesRadioPlayer-HTML5-player-0 div[title="play"],
  #widget-radio-wrapper #MusesRadioPlayer-HTML5-player-0 div[title="stop"] {
    left: 61px !important;
    top: 0 !important;
    width: 54px;
    height: 54px;
  }
}
/* Responsive XS & Small devices Tablets  @screen-xs-max */
@media screen and (min-width: 768px) and (max-width: 1199px) {
  #widget-radio-wrapper {
    width: 163px;
  }
  #widget-radio-wrapper #Player {
    background-image: url('/build/assets/frontend/img/newradio/widget-bg_tablet.jpg');
    background-size: cover;
  }
  #widget-radio-wrapper #MusesRadioPlayer-HTML5-player-0 div[title="play"],
  #widget-radio-wrapper #MusesRadioPlayer-HTML5-player-0 div[title="stop"] {
    left: 61px !important;
    top: 0 !important;
    width: 54px;
    height: 56px;
  }
  #widget-radio-wrapper #MusesRadioPlayer-HTML5-player-0 div div,
  #widget-radio-wrapper #MusesRadioPlayer-HTML5-player-1 div div {
    display: none;
  }
}

#isso-thread .author {
  padding: 0 !important;
}
#isso-thread .author:before {
  background-color: transparent;
}

/*# sourceMappingURL=mods.css.map */
