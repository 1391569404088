.position-h3-new {
  left: 0 !important;
  padding: 0 32px;
}

.ads-list {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.ads-list .ads {
  width: 264px;
  margin: 20px;
}

.text-videos-gray {
    font-weight: 700;
    color: #000;
    margin: 15px 0;
    font-size: 16px;
}
